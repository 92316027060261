import React, {useContext, useState} from "react";
import {useMediaQuery} from "usehooks-ts";
import {PersonView} from "./Person/Person";
import {Person} from "../../Models/Person";
import {PreviousPerson} from "../Icons/PreviousPerson/PreviousPerson";
import {NextPerson} from "../Icons/NextPerson/NextPerson";
import {Colors} from "../../Models/Colors";
import {ColorContext} from "../../Router/AppRouter";

interface TeamProps {
    teamName?: string,
    persons: Person[],
    classname?: string
}

export function Team({teamName = "A Equipa", persons, classname}: TeamProps) {
    const color = useContext(ColorContext);
    const matches = useMediaQuery("(min-width: 768px)");
    const [selectedPerson, setSelectedPerson] = useState(0);

    const handleForwardPerson = () => {
        setSelectedPerson((prev) => (prev < persons.length - 1 ? prev + 1 : 0));
    };

    const handleBackwardsPerson = () => {
        setSelectedPerson((prev) => (prev > 0 ? prev - 1 : persons.length - 1));
    };

    const desktopView = () => {
        return (
            <div className="flex flex-wrap items-center justify-center">
                {persons.map((person) => (
                    <PersonView
                        person={person}
                        className="mx-8 lg:mx-16"
                        key={person.id}
                    />
                ))}
            </div>
        );
    };

    const mobileView = () => {
        return (
            <div className="w-full h-full flex justify-center items-center relative overflow-hidden -mt-20">
                <PreviousPerson onClick={handleBackwardsPerson} />
                <div
                    className="flex relative h-full duration-500"
                    style={{ translate: `${-100 * selectedPerson}%` }}
                >
                    {persons.map((person) => (
                        <div
                            className="w-full h-full overflow-hidden flex-shrink-0 flex-grow-0 flex items-center justify-center "
                            key={person.id}
                        >
                            <PersonView person={person} key={person.id} />
                        </div>
                    ))}
                </div>
                <NextPerson onClick={handleForwardPerson} />
            </div>
        );
    };

    return (
        <div className={`w-full flex flex-col items-center lg:mt-0 mb-32 lg:mb-16 ${classname}`}>
            <h1 className={`font-bold text-6xl ${Colors.text(color)} pt-8 -mt-12 lg:mt-0 lg:pt-0 mb-20 lg:mb-4`}>
                {teamName}
            </h1>
            {matches ? desktopView() : mobileView()}
        </div>
    );
}
