import React, {createContext} from "react";
import {Home} from "../Pages/Home";
import {Navbar} from "../Components/Navbar/Navbar";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ROUTER_APP_PATHS} from "../Constants/Routes";
import {DMF} from "../Pages/DMF";
import {Department} from "../Pages/Department";
import {getCdnImage} from "../Services/ImagesService";
import {IMAGES_ID} from "../Constants/ImagesIds";
import {Colors} from "../Models/Colors";
import {LobitosData} from "../Data/Lobitos";
import {ExploradoresData} from "../Data/Exploradores";
import {PioneirosData} from "../Data/Pioneiros";
import {CaminheirosData} from "../Data/Caminheiros";
import {History} from "../Pages/History";

export const ColorContext = createContext<Colors>(Colors.LOBITOS);

function AppRouter() {



    return (
        <div>
            <BrowserRouter>
                <Navbar />
                <div className="flex pt-20 top-0 left-0 h-screen w-full overflow-hidden">
                    <Routes>
                        <Route
                            path={ROUTER_APP_PATHS.ROOT}
                            element={<Home />}
                        />
                        <Route path={ROUTER_APP_PATHS.DMF} element={<DMF />} />
                        <Route
                            path={ROUTER_APP_PATHS.DEPARTMENT_ONE}
                            element={
                                <ColorContext.Provider value={Colors.LOBITOS}>
                                    <Department
                                        title={LobitosData.title}
                                        welcomeImage={getCdnImage(IMAGES_ID.LOBITOS_FIRST)}
                                        welcomeText={LobitosData.lobitosWelcomeText}
                                        activities={LobitosData.lobitosActivities}
                                        nationalRedirectLink={LobitosData.nationalRedirectLink}
                                        team={LobitosData.lobitosTeam}/>
                                </ColorContext.Provider>
                            }
                        />
                        <Route
                            path={ROUTER_APP_PATHS.DEPARTMENT_TWO}
                            element={
                                <ColorContext.Provider value={Colors.EXPLORADORES}>
                                    <Department
                                        title={ExploradoresData.title}
                                        welcomeImage={getCdnImage(IMAGES_ID.EXPLORADORES_FIRST)}
                                        welcomeText={ExploradoresData.exploradoresWelcomeText}
                                        activities={ExploradoresData.exploradoresActivities}
                                        nationalRedirectLink={ExploradoresData.nationalRedirectLink}
                                        team={ExploradoresData.exploradoresTeam}/>
                                </ColorContext.Provider>
                            }
                        />
                        <Route
                            path={ROUTER_APP_PATHS.DEPARTMENT_THREE}
                            element={
                                <ColorContext.Provider value={Colors.PIONEIROS}>
                                    <Department
                                        title={PioneirosData.title}
                                        welcomeImage={getCdnImage(IMAGES_ID.PIONEIROS_FIRST)}
                                        welcomeText={PioneirosData.pioneirosWelcomeText}
                                        activities={PioneirosData.pioneirosActivities}
                                        nationalRedirectLink={PioneirosData.nationalRedirectLink}
                                        team={PioneirosData.pioneirosTeam}/>
                                </ColorContext.Provider>
                            }
                        />
                        <Route
                            path={ROUTER_APP_PATHS.DEPARTMENT_FOUR}
                            element={
                                <ColorContext.Provider value={Colors.CAMINHEIROS}>
                                    <Department
                                        title={CaminheirosData.title}
                                        welcomeImage={getCdnImage(IMAGES_ID.CAMINHEIROS_FIRST)}
                                        welcomeText={CaminheirosData.caminheirosWelcomeText}
                                        activities={CaminheirosData.caminheirosActivities}
                                        nationalRedirectLink={CaminheirosData.nationalRedirectLink}
                                        team={CaminheirosData.caminheirosTeam}
                                        supportTeam={CaminheirosData.caminheirosSupportTeam}/>
                                </ColorContext.Provider>
                            }
                        />
                        <Route
                            path={ROUTER_APP_PATHS.HISTORY}
                            element={
                                <History />
                            }
                        />
                    </Routes>
                </div>
            </BrowserRouter>
        </div>
    );
}

export default AppRouter;
