import React from "react";
import {ImageCdn} from "../Components/ImageCdn/ImageCdn";
import {getCdnImage} from "../Services/ImagesService";
import {useMediaQuery} from "usehooks-ts";

export function History() {

    const bishop = getCdnImage("tt4a6oohrsghjax4dmqw");
    const joaquim = getCdnImage("mhpqbb1vcohyex7gezv2");
    const sebastiao = getCdnImage("p9adigg4a4izeprzdczm");

    const matches = useMediaQuery("(min-width: 1280px)");

    const desktop = () => {
        return (
            <div className="flex flex-col w-full overflow-y-auto scrollbar px-16">
                <div className="w-full flex justify-center items-center py-8">
                    <h1 className="text-6xl font-extrabold text-primary">História da Região Leiria-Fátima</h1>
                </div>
                <div className="w-full py-10 flex items-center">
                    <div className="w-1/2 pr-16">
                        <p className="text-justify">Não tinham passado ainda dois anos da constituição do Corpo de Scouts Católicos Portugueses, quando a 20 de março de 1925 foi oficializada, pela Junta Central, a Junta Regional de Leiria. O Bispo de Leiria era D. José Alves Correia da Silva, e, tal como outros bispos de outras dioceses, este terá contribuído decisivamente para que o Escutismo católico fosse uma realidade nesta diocese. Surgiram assim, envolvidos como elementos da primeira Junta Regional, o Major Joaquim Pereira dos Reis, como Comissário (cargo análogo a Chefe Regional); o Cónego Sebastião da Costa Brites, então Pároco da Sé, como Diretor (cargo análogo a Assistente) e Augusto Reis, como Inspetor (cargo análogo a Secretário Regional). Logo em maio de 1925 foi criado na Sé o primeiro grupo de escuteiros que seria oficializado como Grupo 14 - Nun’Álvares. Logo de seguida surgiram a Alcateia 7 – Infante Santo, também na Sé de Leiria, a Alcateia 28 – Nª Sra. Fétal, no Reguengo do Fétal e a Alcateia 38 – Sta. Teresa, no Olival.</p>
                    </div>
                    <div className="w-1/2 flex items-center justify-between">
                        <ImageCdn image={bishop} alt="Bispo" className="rounded-tr-3xl rounded-bl-3xl w-80 h-128" />
                        <ImageCdn image={joaquim} alt="Joaquim Pereira dos Reis" className="rounded-tl-3xl rounded-br-3xl w-80 h-128" />
                    </div>
                </div>
                <div className="w-full py-24 flex items-center">
                    <div className="w-1/2 flex justify-center">
                        <ImageCdn image={sebastiao} alt="Sebastiao Costa Brites" className="rounded-tr-3xl rounded-bl-3xl  h-128" />
                    </div>
                    <div className="w-1/2">
                        <p className="text-justify">Nos anos 30, fruto do contexto político da época, as atividades deste grupo e alcateias foram esmorecendo e, só em meados dos anos 40, haveria novas tentativas para reorganização do escutismo católico em Leiria. No entanto, só em 1953 seriam dados os passos determinantes que haveriam de despoletar a chamada 2.ª arrancada do Escutismo em Leiria. Com a persistência do Cónego José Galamba de Oliveira, foi constituída a patrulha de estudos Águia em 1954, com 4 elementos, chefiada por António Poças da Rosa. Esta patrulha definiu uma estratégia que conduziu à constituição de uma nova Junta Regional em 1956, chefiada pelo Dr. Joaquim Coelho Pereira, e posteriormente, à formação dos Agrupamentos 35 - Seminário de Leiria e 36 – Marinha Grande em 1958, ano em que se realizou, o que se pode considerar, como o primeiro Acampamento Regional, nos Andrinos. O Agrupamento 35 do Seminário, foi determinante para o crescimento do número de agrupamentos, uma vez que, alguns dos seus elementos, que se viriam a tornar párocos, foram responsáveis pela criação de vários agrupamentos nas décadas seguintes e em especial após a década de 80.</p>
                    </div>
                </div>
            </div>
        );
    } ;

    const mobile = () => {
        return (
            <div className="flex flex-col w-full overflow-y-auto scrollbar px-8 ">
                <div className="w-full flex justify-center items-center py-8">
                    <h1 className="text-6xl font-extrabold text-primary text-justify">História da Região <br/> Leiria-Fátima</h1>
                </div>
                <div className="w-full flex items-center flex-col">
                    <div className="py-6 ">
                        <p className="text-justify">Não tinham passado ainda dois anos da constituição do Corpo de Scouts Católicos Portugueses, quando a 20 de março de 1925 foi oficializada, pela Junta Central, a Junta Regional de Leiria. O Bispo de Leiria era D. José Alves Correia da Silva, e, tal como outros bispos de outras dioceses, este terá contribuído decisivamente para que o Escutismo católico fosse uma realidade nesta diocese. Surgiram assim, envolvidos como elementos da primeira Junta Regional, o Major Joaquim Pereira dos Reis, como Comissário (cargo análogo a Chefe Regional); o Cónego Sebastião da Costa Brites, então Pároco da Sé, como Diretor (cargo análogo a Assistente) e Augusto Reis, como Inspetor (cargo análogo a Secretário Regional). Logo em maio de 1925 foi criado na Sé o primeiro grupo de escuteiros que seria oficializado como Grupo 14 - Nun’Álvares. Logo de seguida surgiram a Alcateia 7 – Infante Santo, também na Sé de Leiria, a Alcateia 28 – Nª Sra. Fétal, no Reguengo do Fétal e a Alcateia 38 – Sta. Teresa, no Olival.</p>
                    </div>
                    <div className="w-full flex items-center justify-between py-4 flex-col md:flex-row">
                        <ImageCdn image={bishop} alt="Bispo" className="rounded-tr-3xl rounded-bl-3xl w-80 h-128 mb-4 md:mb-0" />
                        <ImageCdn image={joaquim} alt="Joaquim Pereira dos Reis" className="rounded-tl-3xl rounded-br-3xl w-80 h-128" />
                    </div>
                </div>
                <div className="w-full flex flex-col items-center ">
                    <div className=" flex justify-center">
                        <ImageCdn image={sebastiao} alt="Sebastiao Costa Brites" className="rounded-tr-3xl rounded-bl-3xl w-80" />
                    </div>
                    <div className="py-8 ">
                        <p className="text-justify">Nos anos 30, fruto do contexto político da época, as atividades deste grupo e alcateias foram esmorecendo e, só em meados dos anos 40, haveria novas tentativas para reorganização do escutismo católico em Leiria. No entanto, só em 1953 seriam dados os passos determinantes que haveriam de despoletar a chamada 2.ª arrancada do Escutismo em Leiria. Com a persistência do Cónego José Galamba de Oliveira, foi constituída a patrulha de estudos Águia em 1954, com 4 elementos, chefiada por António Poças da Rosa. Esta patrulha definiu uma estratégia que conduziu à constituição de uma nova Junta Regional em 1956, chefiada pelo Dr. Joaquim Coelho Pereira, e posteriormente, à formação dos Agrupamentos 35 - Seminário de Leiria e 36 – Marinha Grande em 1958, ano em que se realizou, o que se pode considerar, como o primeiro Acampamento Regional, nos Andrinos. O Agrupamento 35 do Seminário, foi determinante para o crescimento do número de agrupamentos, uma vez que, alguns dos seus elementos, que se viriam a tornar párocos, foram responsáveis pela criação de vários agrupamentos nas décadas seguintes e em especial após a década de 80.</p>
                    </div>
                </div>
            </div>
        );
    } ;

    return (
        <>
            {matches? desktop() : mobile()}
        </>
    );
}
