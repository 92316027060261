import React from "react";
import "./Doors.css";
import {AdvancedImage, placeholder} from "@cloudinary/react";
import {getCdnImage} from "../../Services/ImagesService";

interface DoorsProps {
  imageId: string;
  alt: string;
  onClick: () => void;
  className?: string;
}

export function Doors({ imageId, alt, onClick, className }: DoorsProps) {
    const doorImage = getCdnImage(imageId);

    return (
        <div className={className} onClick={onClick}>
            <AdvancedImage
                cldImg={doorImage}
                alt={alt}
                className="w-60 h-72 door hover:w-64 hover:h-80 cursor-pointer hover:opacity-80 my-4 md:my-0"
                plugins={[placeholder({ mode: "blur" })]}
            />
        </div>
    );
}
