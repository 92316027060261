import React, {useContext} from "react";
import {ImageCdn} from "../Components/ImageCdn/ImageCdn";
import {useMediaQuery} from "usehooks-ts";
import {Event} from "../Models/Event";
import {Colors} from "../Models/Colors";
import {ActivitiesCalendar} from "../Components/Activities/ActivitiesCalendar";
import {Team} from "../Components/Team/Team";
import {Person} from "../Models/Person";
import {CloudinaryImage} from "@cloudinary/url-gen";
import {ColorContext} from "../Router/AppRouter";

interface DepartmentProps {
    title: string
    welcomeImage: CloudinaryImage
    welcomeText: string
    nationalRedirectLink: string
    activities: Event[]
    team: Person[],
    supportTeam?: Person[]
}

export function Department({ title, welcomeImage, welcomeText, nationalRedirectLink, activities, team, supportTeam }: DepartmentProps) {
    const matches = useMediaQuery("(min-width: 1024px)");
    const color = useContext(ColorContext);

    const desktop = () => {
        return (
            <div className="w-full flex flex-col invisible lg:visible overflow-y-auto scrollbar">
                <div className="w-full h-216   flex items-center justify-center -mt-4 ">
                    <div className="w-1/2 h-screen pl-10 pr-12 flex  items-center flex-col max-h-216 pt-32 pb-28 justify-between  ">
                        <h1 className={`w-full text-7xl xl:text-8xl font-extrabold lg:-mt-8 ${Colors.text(color)}`}>
                            { title }
                        </h1>
                        <p className="text-justify overflow-y-scroll max-h-96 no-scrollbar">
                            { welcomeText }
                        </p>
                        <a
                            className={`${Colors.background(color)} w-48 h-12 flex items-center justify-center rounded-2xl cursor-pointer font-bold text-white text-xl hover:bg-opacity-80 shadow-xl active:shadow-none duration-200`}
                            href={nationalRedirectLink}
                        >
                            Descobre Mais
                        </a>
                    </div>
                    <div className="w-1/2 h-full flex justify-center items-center mr-8">
                        <ImageCdn
                            image={welcomeImage}
                            alt="Lobitos em atividade"
                            className={`w-full rounded-tr-3xl rounded-bl-3xl ${Colors.border(color)} border-2 shadow-lg  `}
                        />
                    </div>
                </div>
                <Team persons={team}/>
                {supportTeam && <Team teamName="EAQS" persons={supportTeam} />}
                <ActivitiesCalendar activities={activities} />
            </div>
        );
    };

    const mobile = () => {
        return (
            <div className="w-full flex flex-col lg:invisible overflow-y-auto scrollbar">
                <div className={`relative shadow-lg ${Colors.background(color)}`}>
                    <ImageCdn
                        image={welcomeImage}
                        alt="Lobitos em atividade"
                        className="w-full"
                    />
                    <div className="absolute top-1/2 -translate-y-1/2 flex w-full items-center flex-col h-full justify-center">
                        <h1 className={`w-full ${title.length > 7? "text-6xl" : " text-8xl"} font-extrabold ${Colors.text(color)} text-center`}>
                            { title }
                        </h1>
                        <a
                            className={`${Colors.background(color)} mt-8 w-52 h-16 flex items-center justify-center rounded-2xl cursor-pointer font-bold text-white text-xl hover:bg-opacity-80 shadow-xl`}
                            href={nationalRedirectLink}
                        >
                            Descobre Mais
                        </a>
                    </div>
                </div>
                <div className={`mx-4 mt-8 p-8 ${Colors.background(color)} rounded-tr-3xl rounded-bl-3xl text-sm shadow-lg`}>
                    <p className="text-justify">
                        { welcomeText }
                    </p>
                </div>
                <Team persons={team} classname="mt-32" />
                {supportTeam && <Team teamName="EAQS" persons={supportTeam} classname="mt-0"/>}
                <ActivitiesCalendar activities={activities}/>
            </div>
        );
    };

    return <>{matches ? desktop() : mobile()}</>;
}
