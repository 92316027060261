export const ROUTER_APP_PATHS = {
    ROOT: "/",
    DMF: "/dmf",
    DEPARTMENT_ONE: "/departamentos/primeira",
    DEPARTMENT_TWO: "/departamentos/segunda",
    DEPARTMENT_THREE: "/departamentos/terceira",
    DEPARTMENT_FOUR: "/departamentos/quarta",
    HISTORY: "/region/history"

};

export const EXTERNAL_ROUTES = {
    QUINTA_ESCUTEIRO: "https://quintadoescuteiro.escutismo.pt",
    NINHO_CORVO: "https://www.instagram.com/ninhodocorvo.cne/",
    ACAREG: "https://www.instagram.com/acareg24.leiriafatima/"
};
