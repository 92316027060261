import React from "react";
import {getCdnImage} from "../Services/ImagesService";
import {IMAGES_ID} from "../Constants/ImagesIds";
import {ImageCdn} from "../Components/ImageCdn/ImageCdn";

export function DMF() {
    const dayOfWeek = (day: string, hourInterval: string) => {
        return (
            <div className="w-96 flex justify-between py-2">
                <p className="font-bold text-3xl text-white mx-4">{day}</p>
                <p className="font-bold text-3xl text-white mx-4">{hourInterval}</p>
            </div>
        );
    };

    const backgroundFull = getCdnImage(IMAGES_ID.DMF_FULL);
    const backgroundMobile = getCdnImage(IMAGES_ID.DMF_MOBILE);

    return (
        <div className="overflow-y-hidden">
            <ImageCdn
                image={backgroundFull}
                className="absolute top-0 w-full h-full  invisible md:visible"
                alt="Background"
            />
            <ImageCdn
                image={backgroundMobile}
                className="absolute top-0 w-full h-full md:invisible"
                alt="Background"
            />
            <div className="absolute top-0 pt-10 w-full h-full flex items-center justify-center flex-col overflow-y-hidden">
                <h1 className="text-9xl text-white font-bold mb-20">DMF</h1>
                <div className=" flex flex-col items-center justify-center">
                    {dayOfWeek("Terça", "21:00 - 22:00")}
                    {dayOfWeek("Quarta", "21:00 - 22:00")}
                    {dayOfWeek("Quinta", "21:00 - 22:00")}
                    {dayOfWeek("Sexta", "18:00 - 20:00")}
                </div>
            </div>
        </div>
    );
}
