import React from "react";
import { Doors } from "../Components/Doors/Doors";
import { IMAGES_ID } from "../Constants/ImagesIds";
import { getCdnImage } from "../Services/ImagesService";
import { ImageCdn } from "../Components/ImageCdn/ImageCdn";
import {EXTERNAL_ROUTES} from "../Constants/Routes";

export function Home() {
    const fullBackground = getCdnImage(IMAGES_ID.BACKGROUND_FULL);
    const smallBackground = getCdnImage(IMAGES_ID.BACKGROUND_MOBILE);

    const navigateTo = (navigate: string) => {
        window.location.href = navigate;
    };

    return (
        <div className="flex flex-col w-full overflow-y-auto scrollbar">
            <ImageCdn
                image={fullBackground}
                className="w-full hidden md:flex"
                alt="Background"
            />
            <ImageCdn
                image={smallBackground}
                className="w-full md:hidden"
                alt="Background"
            />
            <div className="w-full flex  justify-evenly items-center md:items-start flex-col md:flex-row my-14">
                <Doors imageId={IMAGES_ID.QUINTA_ESCUTEIRO} onClick={() => navigateTo(EXTERNAL_ROUTES.QUINTA_ESCUTEIRO)} alt="Quinta do escuterio" />
                <Doors imageId={IMAGES_ID.NINHO_CORVO} onClick={() => navigateTo(EXTERNAL_ROUTES.NINHO_CORVO)} alt="Ninho do Corvo" />
                <Doors imageId={IMAGES_ID.ACAREG} onClick={() => navigateTo(EXTERNAL_ROUTES.ACAREG)} alt="Acareg" />
            </div>
        </div>
    );
}
