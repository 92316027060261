import React, {useContext} from "react";
import {Person} from "../../../Models/Person";
import {ImageCdn} from "../../ImageCdn/ImageCdn";
import {Colors} from "../../../Models/Colors";
import {ColorContext} from "../../../Router/AppRouter";

interface PersonViewProps {
    person: Person;
    className?: string;
}

export function PersonView({ person, className }: PersonViewProps) {
    const color = useContext(ColorContext);

    return (
        <div
            className={`relative flex flex-col ${className} justify-center items-center  py-8 lg:py-4`}
        >
            <ImageCdn
                image={person.image}
                alt={person.name}
                className="w-96 h-96 lg:w-72 lg:h-72 rounded-tl-3xl rounded-br-3xl shadow-lg "
            />
            <div className="absolute bottom-8 lg:bottom-4 backdrop-blur-sm w-full rounded-br-3xl">
                <h1 className={`flex items-center text-center justify-center py-4 lg:py-4  ${Colors.text(color)} font-extrabold text-4xl lg:text-2xl whitespace-nowrap`}>
                    {person.name}
                </h1>
            </div>
        </div>
    );
}
