import React, {useEffect, useState} from "react";
import regionLogo from "./../../assets/logo_regiao.png";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {ROUTER_APP_PATHS} from "../../Constants/Routes";
import {MenuIcon} from "../Icons/MenuIcon/MenuIcon";
import {CloseIcon} from "../Icons/CloseIcon/CloseIcon";
import {useMediaQuery} from "usehooks-ts";
import {BackIcon} from "../Icons/BackIcon/BackIcon";
import AnimatedText from "../AnimatedText/AnimatedText";

enum Options {
    DEFAULT,
    DEPARTMENTS = "Departamentos",
    REGION = "Região"
}

enum AnimationState {
  IDLE,
  STOPPED,
  MOVING,
}

export function Navbar() {
    const [isEnabled, setIsEnables] = useState(false);
    const [departmentsDropDown, setDepartmentsDropDown] = useState(false);
    const [regionDropDown, setRegionDropDown] = useState(false);
    const [optionsDisplaying, setOptionsDisplaying] = useState(Options.DEFAULT);
    const [returnFromSide, setReturnFromSide] = useState(AnimationState.IDLE);

    const location = useLocation();
    const matches = useMediaQuery("(min-width: 768px)");
    const navigate = useNavigate();

    useEffect(() => {
        if (matches) setIsEnables(false);
    }, [matches]);

    useEffect(() => {
        setIsEnables(false);
    }, [location]);

    useEffect(() => {
        if (isEnabled) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isEnabled]);

    useEffect(() => {
        console.log(returnFromSide);
        if (!isEnabled && returnFromSide === AnimationState.STOPPED) {
            setReturnFromSide(AnimationState.IDLE);
            return;
        }

        if (isEnabled && optionsDisplaying != Options.DEFAULT && returnFromSide === AnimationState.IDLE) {
            setReturnFromSide(AnimationState.MOVING);
            return;
        }

        if (isEnabled && optionsDisplaying == Options.DEFAULT && returnFromSide === AnimationState.STOPPED) {
            setReturnFromSide(AnimationState.MOVING);
            return;
        }

        if (!isEnabled && optionsDisplaying != Options.DEFAULT && returnFromSide == AnimationState.MOVING) {
            setReturnFromSide(AnimationState.STOPPED);
        }

        if (!isEnabled && optionsDisplaying == Options.DEFAULT && returnFromSide == AnimationState.MOVING) {
            setReturnFromSide(AnimationState.IDLE);
        }

    }, [optionsDisplaying,isEnabled]);

    const onLogoClick = () => {
        navigate(ROUTER_APP_PATHS.ROOT);
    };

    const DefaultOptions = () => {
        const onDropDownDepartmentsEnter = () => {
            setDepartmentsDropDown(true);
        };

        const onDropDownDepartmentsLeave = () => {
            setDepartmentsDropDown(false);
        };

        const onClickMobile = (option: Options) => {
            if (!isEnabled) return;

            setOptionsDisplaying(option);
        };

        const onDropDownRegionEnter = () => {
            setRegionDropDown(true);
        };

        const onDropDownRegionLeave = () => {
            setRegionDropDown(false);
        };

        return (
            <div className="flex flex-col md:flex-row w-full md:w-auto h-full justify-center md:justify-evenly items-center">
                <NavLink
                    to={ROUTER_APP_PATHS.ROOT}
                    className={({ isActive }) =>
                        `md:mx-4 font-bold text-2xl md:text-base py-4 ${
                            isActive ? "text-primary" : "hover:text-primary"
                        }`
                    }
                >
                    <AnimatedText text="Início" activeAnimation={returnFromSide === AnimationState.MOVING} inverse={true} key="Início" />
                </NavLink>
                <NavLink
                    to={ROUTER_APP_PATHS.DMF}
                    className={({ isActive }) =>
                        `md:mx-4 font-bold text-2xl md:text-base py-4 ${
                            isActive ? "text-primary" : "hover:text-primary"
                        }`
                    }>
                    <AnimatedText text="DMF" activeAnimation={returnFromSide === AnimationState.MOVING} inverse={true} key="DMF"/>
                </NavLink>
                <div
                    className="md:mx-4 text-2xl md:text-base font-bold hover:text-primary cursor-pointer py-4"
                    onMouseEnter={onDropDownRegionEnter}
                    onMouseLeave={onDropDownRegionLeave}
                    onClick={() => onClickMobile(Options.REGION)}
                >
                    <AnimatedText text="Região" activeAnimation={returnFromSide === AnimationState.MOVING} inverse={true} key="Departamentos" />
                    {regionDropDown && (
                        <div className="absolute -translate-x-16 w-48 z-50 bg-white p-4 shadow-md  rounded-b-2xl invisible md:visible">
                            <NavLink
                                to={ROUTER_APP_PATHS.HISTORY}
                                className={({ isActive }) =>
                                    `py-2 flex items-center justify-center mb-2 ${
                                        isActive
                                            ? "bg-primary text-white"
                                            : "hover:text-white hover:bg-primary"
                                    } w-full h-full rounded-2xl`
                                }
                            >
                                História
                            </NavLink>
                        </div>
                    )}
                </div>
                <div
                    className="md:mx-4 text-2xl md:text-base font-bold hover:text-primary cursor-pointer py-4"
                    onMouseEnter={onDropDownDepartmentsEnter}
                    onMouseLeave={onDropDownDepartmentsLeave}
                    onClick={() => onClickMobile(Options.DEPARTMENTS)}
                >
                    <AnimatedText text="Secções" activeAnimation={returnFromSide === AnimationState.MOVING} inverse={true} key="Departamentos" />
                    {departmentsDropDown && (
                        <div className="absolute -translate-x-20 w-48 z-50 bg-white p-4 shadow-md  rounded-b-2xl invisible md:visible">
                            <NavLink
                                to={ROUTER_APP_PATHS.DEPARTMENT_ONE}
                                className={({ isActive }) =>
                                    `py-2 flex items-center justify-center mb-2 ${
                                        isActive
                                            ? "bg-primary text-white"
                                            : "hover:text-white hover:bg-primary"
                                    } w-full h-full rounded-2xl`
                                }
                            >
                                I Secção
                            </NavLink>
                            <NavLink
                                to={ROUTER_APP_PATHS.DEPARTMENT_TWO}
                                className={({ isActive }) =>
                                    `py-2 flex items-center justify-center mb-2 ${
                                        isActive
                                            ? "bg-primary text-white"
                                            : "hover:text-white hover:bg-primary"
                                    } w-full h-full rounded-2xl`
                                }
                            >
                                II Secção
                            </NavLink>
                            <NavLink
                                to={ROUTER_APP_PATHS.DEPARTMENT_THREE}
                                className={({ isActive }) =>
                                    `py-2 flex items-center justify-center mb-2 ${
                                        isActive
                                            ? "bg-primary text-white"
                                            : "hover:text-white hover:bg-primary"
                                    } w-full h-full rounded-2xl`
                                }
                            >
                                III Secção
                            </NavLink>
                            <NavLink
                                to={ROUTER_APP_PATHS.DEPARTMENT_FOUR}
                                className={({ isActive }) =>
                                    `py-2 flex items-center justify-center mb-2 ${
                                        isActive
                                            ? "bg-primary text-white"
                                            : "hover:text-white hover:bg-primary"
                                    } w-full h-full rounded-2xl`
                                }
                            >
                                IV Secção
                            </NavLink>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const mobileMenu = () => {
        if (!isEnabled) return;

        const onCloseClick = () => {
            setIsEnables(false);
        };

        const DepartmentsOptions = () => {
            return (
                <div className="flex flex-col md:flex-row w-full md:w-auto h-full justify-center items-center" >
                    <NavLink
                        to={ROUTER_APP_PATHS.DEPARTMENT_ONE}
                        className={({ isActive }) =>
                            `font-bold text-2xl md:text-base py-4 w-full flex items-center ${
                                isActive ? "text-primary" : "hover:text-primary"
                            }`
                        }
                    >
                        <AnimatedText text="I Secção" key="Iª Secção" activeAnimation={returnFromSide == AnimationState.MOVING}/>
                    </NavLink>
                    <NavLink
                        to={ROUTER_APP_PATHS.DEPARTMENT_TWO}
                        className={({ isActive }) =>
                            `font-bold text-2xl md:text-base py-4 w-full flex items-center ${
                                isActive ? "text-primary" : "hover:text-primary"
                            }`
                        }
                    >
                        <AnimatedText text="II Secção" key="IIª Secção" activeAnimation={returnFromSide == AnimationState.MOVING} />
                    </NavLink>
                    <NavLink
                        to={ROUTER_APP_PATHS.DEPARTMENT_THREE}
                        className={({ isActive }) =>
                            `font-bold text-2xl md:text-base py-4 w-full flex items-center ${
                                isActive ? "text-primary" : "hover:text-primary"
                            }`
                        }
                    >
                        <AnimatedText text="III Secção" key="IIIª Secção" activeAnimation={returnFromSide == AnimationState.MOVING}/>
                    </NavLink>
                    <NavLink
                        to={ROUTER_APP_PATHS.DEPARTMENT_FOUR}
                        className={({ isActive }) =>
                            `font-bold text-2xl md:text-base py-4 w-full flex items-center ${
                                isActive ? "text-primary" : "hover:text-primary"
                            }`
                        }
                    >
                        <AnimatedText text="IV Secção" key="IVª Secção" activeAnimation={returnFromSide == AnimationState.MOVING}/>
                    </NavLink>
                </div>
            );
        };

        const RegionOptions = () => {
            return (
                <div className="flex flex-col md:flex-row w-full md:w-auto h-full justify-center items-center" >
                    <NavLink
                        to={ROUTER_APP_PATHS.HISTORY}
                        className={({ isActive }) =>
                            `font-bold text-2xl md:text-base py-4 w-full flex items-center ${
                                isActive ? "text-primary" : "hover:text-primary"
                            }`
                        }
                    >
                        <AnimatedText text="História" key="History" activeAnimation={returnFromSide == AnimationState.MOVING}/>
                    </NavLink>
                </div>
            );
        };

        const optionsShowing = () => {
            switch (optionsDisplaying) {
                case Options.DEFAULT:
                    return DefaultOptions();
                case Options.DEPARTMENTS:
                    return DepartmentsOptions();
                case Options.REGION:
                    return RegionOptions();
            }
        };

        const onReturnClick = () => {
            setOptionsDisplaying(Options.DEFAULT);
        };

        return (
            <div className="absolute top-0 left-0 w-screen h-screen bg-white bg-opacity-80 px-6 py-6">
                <div
                    className={`w-full flex items-center ${
                        optionsDisplaying === Options.DEFAULT
                            ? "justify-end"
                            : "justify-between"
                    }`}>
                    {optionsDisplaying !== Options.DEFAULT && (
                        <>
                            <BackIcon onClick={onReturnClick} />
                            <h1 className="font-bold text-primary text-3xl">
                                {optionsDisplaying}
                            </h1>
                        </>
                    )}
                    <CloseIcon onClick={onCloseClick} />
                </div>
                <div className="w-full h-full flex flex-col items-center justify-evenly ">
                    {optionsShowing()}
                </div>
            </div>
        );
    };

    const desktop = () => {
        return (
            <div className="w-1/2 hidden md:flex  justify-end items-center">
                {DefaultOptions()}
            </div>
        );
    };

    const mobile = () => {
        if (isEnabled) return;

        const onMenuClick = () => {
            setIsEnables(true);
        };

        return (
            <div className="flex md:hidden">
                <MenuIcon onClick={onMenuClick} />
            </div>
        );
    };

    return (
        <div className="w-full h-20 flex justify-between items-center fixed top-0 px-4 md:px-8  drop-shadow-lg shadow-black bg-white z-50 cursor-pointer">
            <img
                src={regionLogo}
                alt="logo"
                className="w-5/6 md:w-80"
                onClick={onLogoClick}
            />
            {desktop()}
            {mobile()}
            {mobileMenu()}
        </div>
    );
}
