export const IMAGES_ID = {
    BACKGROUND_FULL: "wuwv9pfcwz4oyoreofcy",
    BACKGROUND_MOBILE: "adzg9wuap7hxb9uuclo1",
    QUINTA_ESCUTEIRO: "mp3krnwvlxqwn57x9ahz",
    NINHO_CORVO: "vki8fkzjzrnz7vpiyiym",
    ACAREG: "gbwybgeeqj98mhr4kbe5",
    DMF_FULL: "bcunf7xqa3cgjcbhct9v",
    DMF_MOBILE: "btwlzroqdvcuprubbyvo",
    LOBITOS_FIRST: "dovvkyu8khevado2wlak",
    PERSON_FALLBACK: "tgjv2mbwnwsqp18xa3lj.png",
    EXPLORADORES_FIRST: "bvobjndjeplubhwnfhji",
    PIONEIROS_FIRST: "jcaqa2asyd3ljo2xlwkx",
    CAMINHEIROS_FIRST: "dyxwdxzfy2sfkmpc3sdx"
};
