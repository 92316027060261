import React, {useContext, useEffect, useRef, useState} from "react";
import {Calendar} from "../Calendar/Calendar";
import {ImageCdn} from "../ImageCdn/ImageCdn";
import {Event} from "../../Models/Event";
import {Colors} from "../../Models/Colors";
import {ColorContext} from "../../Router/AppRouter";

interface ActivitiesCalendarProps {
    activities: Event[];
}

export function ActivitiesCalendar({activities}: ActivitiesCalendarProps) {
    const color = useContext(ColorContext);
    const [selectedActivity, setSelectedActivity] = useState<Event>();
    const activityRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setSelectedActivity(undefined);
    }, [activities]);

    useEffect(() => {
        if (!selectedActivity) return;
        activityRef.current?.scrollIntoView({behavior: "smooth"});
    }, [selectedActivity]);

    const onActivitySelect = (evt: Event) => {
        setSelectedActivity(evt);
        activityRef.current?.scrollIntoView({behavior: "smooth"});
    };

    return (
        <div className="">
            <div className="w-full flex items-center justify-center">
                <h1 className={`font-bold text-6xl ${Colors.text(color)}`}>Calendário</h1>
            </div>
            <div className="flex mt-2 lg:mb-2 flex-col lg:flex-row">
                <div className="lg:w-1/2  w-full mb-8 lg:mb-0 px-4 ">
                    <Calendar
                        className="lg:h-168 w-full h-96 "
                        events={activities}
                        onEventClick={onActivitySelect}
                    />
                    <div className="mt-14 lg:mt-2 w-full flex justify-between flex-col lg:flex-row">
                        <div className="flex items-center">
                            <div className={`w-4 h-4 rounded-2xl mr-2 ${Colors.background(color)}`}> </div>
                            <h1>Atividade Regional</h1>
                        </div>
                        <div className="flex items-center">
                            <div className="w-4 h-4 rounded-2xl mr-2 bg-primary"> </div>
                            <h1>Atividade Nacional</h1>
                        </div>
                        <div className="flex items-center">
                            <div className="w-4 h-4 rounded-2xl mr-2 bg-purple-800"> </div>
                            <h1>Atividade Internacional</h1>
                        </div>
                    </div>
                </div>
                <div
                    className="flex flex-col w-full lg:w-1/2  lg:mt-0 px-0 pb-4 lg:pb-6 justify-between items-center pl-4 pr-4"
                    ref={activityRef}
                >
                    {selectedActivity && (
                        <>
                            <div className=" w-full flex items-center flex-col justify-center">
                                <h1 className={`${Colors.text(color)} font-extrabold text-5xl text-center`}>
                                    {selectedActivity.name}
                                </h1>
                                <h2
                                    className={`${Colors.text(color)} text-xl lg:text-3xl w-full text-center `}
                                >
                                    (
                                    {new Date(
                                        selectedActivity.year,
                                        selectedActivity.month,
                                        selectedActivity.startDay,
                                    ).toLocaleDateString()}

                                    {selectedActivity.duration > 1 && ` - ${   
                                        new Date(
                                            selectedActivity.year,
                                            selectedActivity.month,
                                            selectedActivity.startDay + selectedActivity.duration - 1,
                                        ).toLocaleDateString()}`
                                    }

                                    )
                                </h2>
                            </div>
                            {selectedActivity.image && (
                                <div className="w-full flex justify-center ">
                                    <ImageCdn
                                        image={selectedActivity.image}
                                        alt={`Atividade ${selectedActivity.name}`}
                                        className="h-80  rounded-tl-3xl rounded-br-3xl"
                                    />
                                </div>
                            )}

                            <p className="text-justify px-8 pb-12  lg:px-0 max-h-52 lg:max-h-40 overflow-y-auto scrollbar  w-full">
                                {selectedActivity.description}
                            </p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
